<script>
import { trafficLightClassDecider } from "@/shared/util/chart-helper";
import { INVALID_SCORE } from "@/shared/util/const.js";

export default {
  props: {
    certaintyScoreObject: Object,
    updateSelectedCS: Function,
    CSSelectOptions: Array,
    selectedCSDatapoint: String,
    selectedCSDatapointObject: Object,
    chartDataForCScorePerformanceChart: Array,
    csAvgPredictionChartOptions: Object,
    cScoreChart: Object
  },
  data() {
    return {
      INVALID_SCORE: null,
      trafficLightClassDecider: trafficLightClassDecider
    };
  },
  async mounted() {
    // create chart oprions
    this.INVALID_SCORE = INVALID_SCORE;
  }
};
</script>
<template>
  <div>
    <br />
    <!-- CS Overview -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- Not Valid Msg-->
            <div
              v-if="
                certaintyScoreObject.CScore &&
                  certaintyScoreObject.CScore === INVALID_SCORE
              "
              class="row mb-2"
            >
              <!-- Respondent Not Valid Msg -->
              <div class="col-md-12">
                <b-alert show variant="info">
                  <i class="mdi mdi-alert-circle-outline mr-2"></i>
                  {{ $t("dataRecordModal.general.notDataRecordMsg") }}
                </b-alert>
              </div>
            </div>

            <!-- If Cscore is Valid -->
            <div v-else class="row pb-2">
              <div class="col-md-9">
                <!-- Rank Quantile -->
                <div class="row">
                  <div class="col-md-4">
                    <label>
                      {{
                        $t(
                          "dataRecordModal.certaintyScoreTab.generalSection.quantileRank"
                        )
                      }}
                    </label>
                  </div>
                  <div class="col-md-8">
                    {{ certaintyScoreObject.rankPercentile }}
                  </div>
                </div>
                <!-- Valid Data Points -->
                <div class="row">
                  <div class="col-md-4">
                    <label>
                      {{
                        $t(
                          "dataRecordModal.certaintyScoreTab.generalSection.validDataPoints"
                        )
                      }}
                    </label>
                  </div>
                  <div class="col-md-8">
                    {{ certaintyScoreObject.validDataPoints }}
                    /
                    {{ certaintyScoreObject.totalDataPoints }}
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <apexchart
                  ref="meanChart"
                  class="apex-charts apexChartData"
                  type="radialBar"
                  height="150"
                  dir="ltr"
                  :series="[certaintyScoreObject.CScore.toFixed(3)]"
                  :options="cScoreChart.chartOptions"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- CS Data Points -->
    <div
      class="row"
      v-if="
        (certaintyScoreObject.CScore || certaintyScoreObject.CScore === 0) &&
          certaintyScoreObject.CScore !== INVALID_SCORE
      "
    >
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- Card Topic -->
            <b-badge class="mb-3" style="font-size: 12px" variant="primary">
              {{
                $t("dataRecordModal.certaintyScoreTab.dataPointSection.title")
              }}</b-badge
            >
            <div
              class="row"
              v-for="(datapoint,
              datapointIndex) in certaintyScoreObject.datapoints"
              :key="datapointIndex"
            >
              <div class="col-md-4">
                <label>
                  {{
                    $t(
                      "dataRecordModal.certaintyScoreTab.dataPointSection.label"
                    )
                  }}
                  {{ datapointIndex + 1 }}
                </label>
              </div>
              <div class="col-md-2">
                <span v-if="datapoint.CScore === INVALID_SCORE">
                  {{ $t("dataRecordModal.general.notAvailable") }}
                </span>
                <span v-else>
                  {{
                    datapoint.CScore.toLocaleString("de-De", {
                      minimumFractionDigits: 3
                    })
                  }}
                </span>
              </div>
              <div v-if="datapoint.CScore !== INVALID_SCORE">
                <!-- Traffic Light -->
                <span>
                  <i
                    class="bx bxs-circle text-primary ml-2"
                    :class="trafficLightClassDecider(datapoint.CScore)"
                    style="font-size: 15px"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- CScore Performance Chart -->
    <div class="row" v-if="certaintyScoreObject.CScore !== INVALID_SCORE">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- Card Topic -->
            <b-badge class="mb-3" style="font-size: 12px" variant="primary">
              {{
                $t(
                  "dataRecordModal.certaintyScoreTab.falseConsensusChart.title"
                )
              }}</b-badge
            >
            <!-- Chart -->
            <div>
              <div class="mb-3" v-if="CSSelectOptions.length > 1">
                <label>
                  {{
                    $t(
                      "dataRecordModal.certaintyScoreTab.falseConsensusChart.dropdownLabel"
                    )
                  }}: &nbsp;
                </label>
                <b-form-select
                  @change="updateSelectedCS"
                  class="ml-4 col-md-4"
                  v-model="selectedCSDatapoint"
                  size="sm"
                  :options="CSSelectOptions"
                >
                </b-form-select>
              </div>
              <div class="mb-3">
                <label>
                  <!-- {{
                                $t(
                                  "dataRecordModal.itemBatteryScoreTab.itemBehaviour.dropdownLabel"
                                )
                              }}-->
                  <!-- TODO -->
                  Selected Answer : &nbsp;
                  {{ this.selectedCSDatapointObject.respondentChosenAnswer }}
                </label>
              </div>
              <apexchart
                ref="performanceChart3"
                class="apex-charts mt-3 mb-3"
                dir="ltr"
                type="bar"
                height="300"
                :series="[...chartDataForCScorePerformanceChart]"
                :options="{
                  ...csAvgPredictionChartOptions.chartOptions
                }"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
