<script>
import { trafficLightClassDecider } from "@/shared/util/chart-helper";
import { INVALID_SCORE } from "@/shared/util/const.js";
import { EventBus } from "@/shared/util/event-bus.js";
import { getLanaguegName, mapOESCategories } from "@/shared/util/helpers.js";

export default {
  props: {
    openEndedScoreObject: Object,
    openEndedScoreChartOptions: Object,
    dataRecordId: String,
    datafileId: String
  },
  components: {},
  data() {
    return {
      INVALID_SCORE: null,
      selectedDataRecord: null,
      trafficLightClassDecider: trafficLightClassDecider,
      dataPointIdentifierForFeedback: null,
      questionTitleForFeedback: null
    };
  },
  async mounted() {
    // create chart oprions
    this.INVALID_SCORE = INVALID_SCORE;
  },
  methods: {
    findOESCategory(category) {
      return mapOESCategories(category);
    },
    clickFeedback(datapoint) {
      this.dataPointIdentifierForFeedback = datapoint.datapointIdentifier;
      this.questionTitleForFeedback = datapoint.questionTitle;

      this.selectedDataRecord = {
        dataRecordId: this.dataRecordId,
        answerFromRespondent: datapoint.answer,
        qualityScore: datapoint.oeScore
      };

      EventBus.$emit(
        "showOESFeedbackModal",
        this.dataPointIdentifierForFeedback,
        this.questionTitleForFeedback,
        this.selectedDataRecord
      );
    },
    findLanguageWithCode(code) {
      return getLanaguegName(code);
    },
    getAllDuplicateRefs(duplicateRefs) {
      return duplicateRefs.split(",");

      // .map((duplicateRef) => {
      //   console.log(duplicateRef);
      //   return (
      // return (
      //   <span
      //     class="badge badge-primary font-size-12 oes-answer-cat-dr"
      //     style="cursor: pointer"
      //     onClick={() => this.clickOnDuplicateRespondent(duplicateRef)}
      //   >
      //     {/* {duplicateRef} */}
      //   </span>
      //   );
      // });
    },
    clickOnDuplicateRespondent(respondentId) {
      EventBus.$emit("showDuplicatedRespondent", respondentId);
    }
  }
};
</script>
<template>
  <div>
    <!-- OES Overview -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- Not Valid Msg-->
            <div
              v-if="
                openEndedScoreObject.oeScore &&
                openEndedScoreObject.oeScore === INVALID_SCORE
              "
              class="row mb-2"
            >
              <!-- Respondent Not Valid Msg -->
              <div class="col-md-12">
                <b-alert show variant="info">
                  <i class="mdi mdi-alert-circle-outline mr-2"></i>
                  {{ $t("dataRecordModal.general.notDataRecordMsg") }}
                </b-alert>
              </div>
            </div>

            <!-- If score is Valid -->
            <div v-else class="row pb-2">
              <div class="col-md-9">
                <!-- Rank Quantile -->
                <div class="row">
                  <div class="col-md-5">
                    <label>
                      {{
                        $t(
                          "dataRecordModal.predictionScoreTab.generalSection.quantileRank"
                        )
                      }}
                    </label>
                  </div>
                  <div class="col-md-7">
                    {{ openEndedScoreObject.rankPercentile }}
                  </div>
                </div>

                <!-- Valid Data Points -->
                <div class="row">
                  <div class="col-md-5">
                    <label>
                      {{
                        $t(
                          "dataRecordModal.predictionScoreTab.generalSection.validDataPoints"
                        )
                      }}
                    </label>
                  </div>
                  <div class="col-md-7">
                    {{ openEndedScoreObject.validDataPoints }}
                    /
                    {{ openEndedScoreObject.totalDataPoints }}
                  </div>
                </div>

                <!-- Duplicate Check -->
                <div class="row">
                  <div class="col-md-5">
                    <label>
                      {{
                        $t(
                          "dataRecordModal.openEndedScoreTab.duplicateCheckActivated"
                        )
                      }}
                    </label>
                  </div>
                  <div class="col-md-7">
                    <span class="" v-if="openEndedScoreObject.enableDuplicateChecks">
                      <b-badge style="font-size: 12px" variant="info">
                        {{
                          $t(
                            "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.yes"
                          )
                        }}
                      </b-badge>
                    </span>
                    <span class="" v-if="!openEndedScoreObject.enableDuplicateChecks">
                      <b-badge style="font-size: 12px" variant="light">
                        {{ $t( "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.no" ) }}
                      </b-badge>
                    </span>
                  </div>
                </div>

                <!-- Context Check -->
                <div class="row">
                  <div class="col-md-5">
                    <label>
                      {{
                        $t(
                          "dataRecordModal.openEndedScoreTab.contextCheckActivated"
                        )
                      }}
                    </label>
                  </div>
                  <div class="col-md-7">
                    <span class="" v-if="openEndedScoreObject.isEnableWrongTopicCheck">
                      <b-badge style="font-size: 12px" variant="info">
                        {{
                          $t(
                            "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.yes"
                          )
                        }}
                      </b-badge>
                    </span>
                    <span class="" v-if="!openEndedScoreObject.isEnableWrongTopicCheck">
                      <b-badge style="font-size: 12px" variant="light">
                        {{ $t( "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.no" ) }}
                      </b-badge>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <apexchart
                  ref="meanChart"
                  class="apex-charts apexChartData"
                  type="radialBar"
                  height="150"
                  dir="ltr"
                  :series="[openEndedScoreObject.oeScore.toFixed(3)]"
                  :options="openEndedScoreChartOptions.chartOptions"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- OES Data Points -->
    <div
      class="row"
      v-if="
        openEndedScoreObject.oeScore !== null &&
        openEndedScoreObject.oeScore !== 'undefined' &&
        openEndedScoreObject.oeScore !== INVALID_SCORE
      "
    >
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- Card Topic -->
            <b-badge class="mb-3" style="font-size: 12px" variant="primary">
              {{
                $t("dataRecordModal.openEndedScoreTab.openEndedScores")
              }}</b-badge
            >
            <div
              class="row"
              v-for="(
                datapoint, datapointIndex
              ) in openEndedScoreObject.datapoints"
              :key="datapointIndex"
            >
              <div class="col-md-4">
                <label class="text-limt">
                  <!-- {{
                    $t(
                      "dataRecordModal.predictionScoreTab.dataPointSection.label"
                    )
                  }} -->
                  {{ datapoint.datapointIdentifier }}
                </label>
              </div>
              <div class="col-md-3">
                <span v-if="datapoint.oeScore === INVALID_SCORE">
                  {{ $t("dataRecordModal.general.notAvailable") }}
                </span>
                <span v-else>
                  {{
                    datapoint.oeScore.toLocaleString("de-De", {
                      minimumFractionDigits: 3
                    })
                  }}
                </span>
              </div>
              <div v-if="datapoint.oeScore !== INVALID_SCORE">
                <!-- Traffic Light -->
                <span>
                  <i
                    class="bx bxs-circle text-primary ml-2"
                    :class="trafficLightClassDecider(datapoint.oeScore)"
                    style="font-size: 15px"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- OES Score Performance Chart -->
    <div
      class="row"
      v-if="
        openEndedScoreObject.oeScore !== null &&
        openEndedScoreObject.oeScore !== 'undefined' &&
        openEndedScoreObject.oeScore !== INVALID_SCORE
      "
    >
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- Card Topic -->
            <b-badge class="mb-4" style="font-size: 12px" variant="primary">
              {{ $t("dataRecordModal.openEndedScoreTab.datapoints") }}</b-badge
            >

            <!-- Data Points -->
            <div
              v-for="(
                dataPoint, dataPointIndex
              ) in openEndedScoreObject.datapoints"
              :key="dataPointIndex"
              class="mb-3"
            >
              <hr v-if="dataPointIndex !== 0" />
              <div class="row">
                <div class="col-md-12">
                  <!-- Data point identifer  -->
                  <div class="row">
                    <div class="col-md-4">
                      <label>
                        {{
                          $t(
                            "dataRecordModal.predictionScoreTab.dataPointSection.label"
                          )
                        }}:
                      </label>
                    </div>
                    <div class="col-md-8">
                      {{ dataPoint.datapointIdentifier }}
                    </div>
                  </div>
                  <!-- Question -->
                  <div class="row mt-3">
                    <div class="col-md-4">
                      <label
                        >{{ $t("dataRecordModal.openEndedScoreTab.question") }}:
                      </label>
                    </div>
                    <div class="col-md-8">{{ dataPoint.questionTitle }}</div>
                  </div>
                  <!-- Keywords -->
                  <div
                    class="row mt-3"
                    v-if="dataPoint.keywords && dataPoint.keywords.length > 0"
                  >
                    <div class="col-md-4">
                      <label
                        >{{ $t("dataRecordModal.openEndedScoreTab.keywords") }}:
                      </label>
                    </div>
                    <div class="col-md-8">
                      <span
                        v-for="(keyword, keywordIndex) in dataPoint.keywords"
                        :key="keywordIndex"
                      >
                        <b-badge
                          style="font-size: 12px"
                          variant="success"
                          class="mr-2 mb-1"
                        >
                          {{ keyword }}
                        </b-badge>
                      </span>
                    </div>
                  </div>
                  <!-- Answer -->
                  <div class="row mt-3">
                    <div class="col-md-4">
                      <label
                        >{{ $t("dataRecordModal.openEndedScoreTab.answer") }}:
                      </label>
                    </div>
                    <div class="col-md-8" v-if="dataPoint.answer">
                      <span v-if="dataPoint.answer">
                        {{ dataPoint.answer }}</span
                      ><span v-else> - </span>
                    </div>
                  </div>
                  <!-- Category -->
                  <div class="row mt-3">
                    <div class="col-md-4">
                      <label>
                        {{
                          $t(
                            "dataRecordModal.openEndedScoreTab.answerCategory"
                          )
                        }}:
                      </label>
                    </div>
                    <div class="col-md-8">
                      <span v-if="dataPoint.answerCategory === '-999'">
                        -
                      </span>
                      <span
                        v-if="dataPoint.answerCategory !== '-999'"
                        class="badge badge-silver font-size-12"
                        :class="{
                          'oes-answer-cat-va':
                            `${dataPoint.answerCategory}` == 1,
                          'oes-answer-cat-ga':
                            `${dataPoint.answerCategory}` == 4,
                          'oes-answer-cat-pr':
                            `${dataPoint.answerCategory}` == 6,
                          'oes-answer-cat-no':
                            `${dataPoint.answerCategory}` == 7,
                          'oes-answer-cat-dr':
                            `${dataPoint.answerCategory}` == 8,
                          'oes-answer-cat-da':
                            `${dataPoint.answerCategory}` == 9,
                          'oes-answer-cat-wt':
                            `${dataPoint.answerCategory}` == 10,
                          'oes-answer-cat-wl':
                            `${dataPoint.answerCategory}` == 11,
                          'oes-answer-cat-cp':
                            `${dataPoint.answerCategory}` == 12,
                          'oes-answer-cat-ar':
                            `${dataPoint.answerCategory}` == 13,
                          'oes-answer-cat-ai-error':
                            `${dataPoint.answerCategory}` == -2
                        }"
                      >
                        {{ findOESCategory(dataPoint.answerCategory) }}
                      </span>
                    </div>
                  </div>
                  <!-- Detected Language -->
                  <div
                    class="row mt-3"
                    v-if="dataPoint.answer && dataPoint.answerCategory == 11"
                  >
                    <div class="col-md-4">
                      <label
                        >{{
                          $t(
                            "dataRecordModal.openEndedScoreTab.nonExpetctedLanguage"
                          )
                        }}:
                      </label>
                    </div>
                    <span class="col-md-8">
                      <i class="bx bxs-flag text-danger"></i>
                      {{
                        findLanguageWithCode(dataPoint.answerDetectedLanguage)
                      }}
                    </span>
                  </div>
                  <!-- Reason for Duplicate Respondent -->
                  <div
                    class="row mt-3"
                    v-if="dataPoint.answer && dataPoint.answerCategory == 8"
                  >
                    <div class="col-md-4">
                      <label
                        >{{
                          $t("dataRecordModal.openEndedScoreTab.duplicateWith")
                        }}:</label
                      >
                    </div>
                    <span class="col-md-8">
                      <span
                        v-for="(
                          duplicateRef, duplicateRefIndex
                        ) in getAllDuplicateRefs(dataPoint.duplicateReasons)"
                        :key="duplicateRefIndex"
                        class="mr-1 badge badge-primary font-size-12 oes-answer-cat-dr"
                        style="cursor: pointer"
                        @click="clickOnDuplicateRespondent(duplicateRef)"
                      >
                        {{ duplicateRef }}
                      </span>
                    </span>
                  </div>
                  <!-- Reason for Duplicate Answer -->
                  <div
                    class="row mt-3"
                    v-if="dataPoint.answer && dataPoint.answerCategory == 9"
                  >
                    <div class="col-md-4">
                      <label
                        >{{
                          $t("dataRecordModal.openEndedScoreTab.duplicatePart")
                        }}:</label
                      >
                    </div>
                    <span class="col-md-8">
                      <b> {{ dataPoint.duplicateReasons }} </b>
                    </span>
                  </div>
                  <!-- Not Avaiable Msg -->
                  <div class="row" v-if="!dataPoint.answer">
                    <div class="col-md-12">
                      <b-alert show variant="info">
                        <i class="mdi mdi-alert-circle-outline mr-2"></i>
                        {{ $t("dataRecordModal.general.notDataRecordMsg") }}
                      </b-alert>
                    </div>
                  </div>
                </div>
                <!-- TODO: Uncomment this before release user feedback system -->
                <!-- <div class="col-md-2 text-right">
                  <b-button
                    variant="warning"
                    size="sm"
                    @click="clickFeedback(dataPoint)"
                    ><i class="bx bxs-pencil"></i>
                  </b-button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.text-limt {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
}
</style>
