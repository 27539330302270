<script>
import { trafficLightClassDecider } from "@/shared/util/chart-helper";
import { INVALID_SCORE } from "@/shared/util/const.js";
import { RScoreChart, RScorePresentationChart } from "../../../result-charts";

export default {
  props: {
    overviewDataObject: Object
  },
  data() {
    return {
      /******** Data Variables ******/
      INVALID_SCORE: null,
      rScorePresentationChartOptions: null,
      rScoreChart: null,
      trafficLightClassDecider: trafficLightClassDecider
    };
  },
  created() {
    this.rScorePresentationChartOptions = new RScorePresentationChart();
  },
  async mounted() {
    // create chart oprions
    this.rScoreChart = new RScoreChart();
    this.INVALID_SCORE = INVALID_SCORE;
  },
  methods: {
    getPresentationChartData(respondantWeights) {
      return [
        respondantWeights.OES * 100,
        respondantWeights.TS * 100,
        respondantWeights.IBS * 100,
        respondantWeights.PS * 100,
        respondantWeights.CS * 100
      ];
    }
  }
};
</script>
<template>
  <!-- Overview Tab -->

  <div>
    <!-- Overall Details -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- Respondent has no R-Score message-->
            <div
              v-if="this.overviewDataObject.datafileRScore === INVALID_SCORE"
              class="row mb-2"
            >
              <!-- Respondent Not Valid Msg -->
              <div class="col-md-12">
                <b-alert show variant="info">
                  <i class="mdi mdi-alert-circle-outline mr-2"></i>
                  {{ $t("dataRecordModal.general.notDataRecordMsg") }}
                </b-alert>
              </div>
            </div>
            <div v-else>
              <!-- Card Topic -->
              <b-badge class="mb-3" style="font-size: 12px" variant="primary">
                {{ $t("dataRecordModal.summaryTab.generalSection.title") }}
              </b-badge>
              <div class="row">
                <!-- General -->
                <div class="col-md-8">
                  <!-- R Score -->
                  <div class="row">
                    <div class="col-md-6">
                      <label>
                        {{
                          $t("dataRecordModal.summaryTab.generalSection.rScore")
                        }}
                      </label>
                    </div>
                    <div
                      class="col-md-6"
                      v-if="
                        this.overviewDataObject.datafileRScore !== INVALID_SCORE
                      "
                    >
                      {{ this.overviewDataObject.datafileRScore.toFixed(3) }}
                    </div>
                    <div class="col-md-6" v-else>N/A</div>
                  </div>
                  <!-- Rank Quantile-->
                  <div class="row">
                    <div class="col-md-6">
                      <label>
                        {{
                          $t(
                            "dataRecordModal.summaryTab.generalSection.quantileRank"
                          )
                        }}
                      </label>
                    </div>
                    <div class="col-md-6">
                      {{ this.overviewDataObject.rankPercentile }}
                    </div>
                  </div>
                  <!-- Valid Data Points -->
                  <div class="row">
                    <div class="col-md-6">
                      <label>
                        {{
                          $t(
                            "dataRecordModal.summaryTab.generalSection.validDataPoints"
                          )
                        }}
                      </label>
                    </div>
                    <div class="col-md-6">
                      {{ this.overviewDataObject.validDataPoints }}
                      /
                      {{ this.overviewDataObject.totalDataPoints }}
                    </div>
                  </div>
                  <!-- Standard Deviation -->
                  <div
                    class="row"
                    v-if="
                      this.overviewDataObject.standardDeviation &&
                        this.overviewDataObject.averageStandardDeviation &&
                        this.overviewDataObject.standardDeviation !==
                          INVALID_SCORE
                    "
                  >
                    <div class="col-md-6">
                      <label>
                        {{
                          $t(
                            "dataRecordModal.summaryTab.generalSection.stdDeviation"
                          )
                        }}
                      </label>
                    </div>
                    <div class="col-md-6">
                      {{ this.overviewDataObject.standardDeviation.toFixed(3) }}
                      /
                      {{
                        this.overviewDataObject.averageStandardDeviation.toFixed(
                          3
                        )
                      }}
                    </div>
                  </div>
                </div>
                <!-- Rscore Chart-->
                <div
                  class="col-md-4"
                  v-if="this.overviewDataObject.datafileRScore !== INVALID_SCORE"
                >
                  <apexchart
                    ref="meanChart"
                    class="apex-charts apexChartData"
                    type="radialBar"
                    :height="200"
                    dir="ltr"
                    :series="[this.overviewDataObject.datafileRScore.toFixed(3)]"
                    :options="rScoreChart.chartOptions"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Quality Scores Details  -->
    <div class="row" v-if="this.overviewDataObject.datafileRScore !== INVALID_SCORE">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-8">
                <!-- Card Topic -->
                <div>
                  <b-badge
                    class="mb-3"
                    style="font-size: 12px"
                    variant="primary"
                  >
                    {{
                      $t("dataRecordModal.summaryTab.qualityScoreSection.title")
                    }}</b-badge
                  >

                  <!-- OES -->
                  <div
                    class="row"
                    v-if="
                      !isNaN(this.overviewDataObject.OEScore) &&
                        this.overviewDataObject.OEScore !== INVALID_SCORE
                    "
                  >
                    <div class="col-md-4">
                      <label>
                        {{
                          $t(
                            "dataRecordModal.summaryTab.qualityScoreSection.OES"
                          )
                        }}
                      </label>
                    </div>
                    <div class="col-md-3">
                      {{
                        this.overviewDataObject.OEScore.toLocaleString(
                          "de-De",
                          {
                            minimumFractionDigits: 3
                          }
                        )
                      }}
                    </div>
                    <div class="col-md-5">
                      <!-- Traffic Light -->
                      <span>
                        <i
                          class="bx bxs-circle text-primary ml-2"
                          :class="
                            trafficLightClassDecider(
                              this.overviewDataObject.OEScore
                            )
                          "
                          style="font-size: 15px"
                        ></i>
                      </span>
                    </div>
                  </div>

                  <!-- TS -->
                  <div
                    class="row"
                    v-if="
                      !isNaN(this.overviewDataObject.timeScore) &&
                        this.overviewDataObject.timeScore !== INVALID_SCORE
                    "
                  >
                    <div class="col-md-4">
                      <label>
                        {{
                          $t(
                            "dataRecordModal.summaryTab.qualityScoreSection.TS"
                          )
                        }}
                      </label>
                    </div>
                    <div class="col-md-3">
                      {{
                        this.overviewDataObject.timeScore.toLocaleString(
                          "de-De",
                          {
                            minimumFractionDigits: 3
                          }
                        )
                      }}
                    </div>
                    <div class="col-md-5">
                      <!-- Traffic Light -->
                      <span>
                        <i
                          class="bx bxs-circle text-primary ml-2"
                          :class="
                            trafficLightClassDecider(
                              this.overviewDataObject.timeScore
                            )
                          "
                          style="font-size: 15px"
                        ></i>
                      </span>
                    </div>
                  </div>

                  <!-- IBS -->
                  <div
                    class="row"
                    v-if="
                      !isNaN(this.overviewDataObject.itemBatteryScore) &&
                        this.overviewDataObject.itemBatteryScore !==
                          INVALID_SCORE
                    "
                  >
                    <div class="col-md-4">
                      <label>
                        {{
                          $t(
                            "dataRecordModal.summaryTab.qualityScoreSection.IBS"
                          )
                        }}
                      </label>
                    </div>
                    <div class="col-md-3">
                      {{
                        this.overviewDataObject.itemBatteryScore.toLocaleString(
                          "de-De",
                          { minimumFractionDigits: 3 }
                        )
                      }}
                    </div>
                    <div class="col-md-5">
                      <!-- Traffic Light -->
                      <span>
                        <i
                          class="bx bxs-circle text-primary ml-2"
                          :class="
                            trafficLightClassDecider(
                              this.overviewDataObject.itemBatteryScore
                            )
                          "
                          style="font-size: 15px"
                        ></i>
                      </span>
                    </div>
                  </div>

                  <!-- Prediction Score -->
                  <div
                    class="row"
                    v-if="
                      !isNaN(this.overviewDataObject.predictionScore) &&
                        this.overviewDataObject.predictionScore !==
                          INVALID_SCORE
                    "
                  >
                    <div class="col-md-4">
                      <label>
                        {{
                          $t(
                            "dataRecordModal.summaryTab.qualityScoreSection.PS"
                          )
                        }}
                      </label>
                    </div>
                    <div class="col-md-3">
                      {{
                        this.overviewDataObject.predictionScore.toLocaleString(
                          "de-De",
                          { minimumFractionDigits: 3 }
                        )
                      }}
                      <!-- Traffic Light -->
                    </div>
                    <div class="col-md-5">
                      <span>
                        <i
                          class="bx bxs-circle text-primary ml-2"
                          :class="
                            trafficLightClassDecider(
                              this.overviewDataObject.predictionScore
                            )
                          "
                          style="font-size: 15px"
                        ></i>
                      </span>
                    </div>
                  </div>

                  <!-- CS -->
                  <div
                    class="row"
                    v-if="
                      !isNaN(this.overviewDataObject.CScore) &&
                        this.overviewDataObject.CScore !== INVALID_SCORE
                    "
                  >
                    <div class="col-md-4">
                      <label>
                        {{
                          $t(
                            "dataRecordModal.summaryTab.qualityScoreSection.CS"
                          )
                        }}
                      </label>
                    </div>
                    <div class="col-md-3">
                      {{
                        this.overviewDataObject.CScore.toLocaleString("de-De", {
                          minimumFractionDigits: 3
                        })
                      }}
                    </div>
                    <div class="col-md-5">
                      <!-- Traffic Light -->
                      <span>
                        <i
                          class="bx bxs-circle text-primary ml-2"
                          :class="
                            trafficLightClassDecider(
                              this.overviewDataObject.CScore
                            )
                          "
                          style="font-size: 15px"
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Presentation Chart -->
              <div class="col-md-4">
                <apexchart
                  ref="RScorePresenattionChart"
                  class="apex-charts mt-4"
                  height="250"
                  type="pie"
                  dir="ltr"
                  :series="
                    getPresentationChartData(
                      this.overviewDataObject.respondentWeights
                    )
                  "
                  :options="rScorePresentationChartOptions.chartOptions"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
