import CustomErrorMessage from "@/shared/util/customErrorMessage.js";
import { ApiCall } from "../apiBase";

class MarkingService {
  async markDatafile(datafileId, threshold) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
        mutation markDatafile($datafileId: ID!, $threshold: Float!) {
          markDatafile (datafileId: $datafileId, threshold: $threshold) {
           status
           message
          }
        }
      `,
        variables: {
          datafileId,
          threshold
        }
      }
    });

    if (result.data.data?.markDatafile.status === "SUCCESS") {
      return true;
    } else {
      throw new CustomErrorMessage(
        result?.data?.errors[0]?.extensions?.code,
        result?.data?.errors[0].message
      );
    }
  }
}

export default new MarkingService();
