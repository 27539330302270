import { ApiCall } from "../apiBase";

class WeightingService {
  async weightDatafile(datafileId, weightingSettings) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
        mutation weightDatafile($datafileId: ID!, $algorithms: [WeightingMethodInput]!, $projectiveQuestions: [ProjectiveQuestionWeightingInput]) {
          weightDatafile (datafileId: $datafileId, algorithms: $algorithms, projectiveQuestions: $projectiveQuestions) {
           status
           message
          }
        }
      `,
        variables: {
          datafileId,
          algorithms: weightingSettings.datafileWeighting,
          projectiveQuestions: weightingSettings.projectiveQuestionsWeighting
        }
      }
    });

    if (result.data.data.weightDatafile.status === "SUCCESS") {
      return true;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

  async resetDatafile(datafileId) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
        mutation resetDatafile($datafileId: ID!) {
          resetDatafile (datafileId: $datafileId) {
           status
           message
          }
        }
      `,
        variables: {
          datafileId
        }
      }
    });

    if (result.data.data.resetDatafile.status === "SUCCESS") {
      return true;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }
}

export default new WeightingService();
