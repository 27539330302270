<script>
import { trafficLightClassDecider } from "@/shared/util/chart-helper";
import { INVALID_SCORE } from "@/shared/util/const.js";

export default {
  props: {
    timeScoreDataObject: Object,
    timeScoreComparisonChartData: Array,
    timeScoreComparisonChartOptions: Object,
    timeScoreChart: Object
  },
  data() {
    return {
      INVALID_SCORE: null,
      trafficLightClassDecider: trafficLightClassDecider
    };
  },
  async mounted() {
    this.INVALID_SCORE = INVALID_SCORE;
  }
};
</script>
<template>
  <div>
    <br />
    <!-- Time Score Overview -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- Time and Not Valid Msg-->
            <div
              v-if="
                timeScoreDataObject.timeScore &&
                  timeScoreDataObject.timeScore === INVALID_SCORE
              "
              class="row mb-2"
            >
              <!-- Respondent Not Valid Msg -->
              <div class="col-md-12">
                <b-alert show variant="info">
                  <i class="mdi mdi-alert-circle-outline mr-2"></i>
                  {{ $t("dataRecordModal.general.notDataRecordMsg") }}
                </b-alert>
              </div>
            </div>

            <!-- If Time score is Valid -->
            <div v-else class="row pb-2">
              <div class="col-md-9">
                <!-- Rank Quantile -->
                <div class="row">
                  <div class="col-md-4">
                    <label>
                      {{
                        $t(
                          "dataRecordModal.timeScoreTab.generalSection.quantileRank"
                        )
                      }}
                    </label>
                  </div>
                  <div class="col-md-8">
                    {{ timeScoreDataObject.rankPercentile }}
                  </div>
                </div>
                <!-- Valid Data Points -->
                <div class="row">
                  <div class="col-md-4">
                    <label>
                      {{
                        $t(
                          "dataRecordModal.timeScoreTab.generalSection.validDataPoints"
                        )
                      }}
                    </label>
                  </div>
                  <div class="col-md-8">
                    {{ timeScoreDataObject.validDataPoints }}
                    /
                    {{ timeScoreDataObject.totalDataPoints }}
                  </div>
                </div>
                <!-- Group -->
                <div class="row" v-if="timeScoreDataObject.group">
                  <div class="col-md-4">
                    <label>
                      {{
                        $t(
                          "dataRecordModal.timeScoreTab.generalSection.scoringGroup"
                        )
                      }}
                    </label>
                  </div>
                  <div class="col-md-8">
                    {{ timeScoreDataObject.group }}
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <apexchart
                  ref="meanChart"
                  class="apex-charts apexChartData"
                  type="radialBar"
                  height="150"
                  dir="ltr"
                  :series="[timeScoreDataObject.timeScore.toFixed(3)]"
                  :options="timeScoreChart.chartOptions"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Time Points -->
    <div
      class="row"
      v-if="
        (timeScoreDataObject.timeScore ||
          timeScoreDataObject.timeScore === 0) &&
          timeScoreDataObject.timeScore !== INVALID_SCORE
      "
    >
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- Card Topic -->
            <b-badge class="mb-3" style="font-size: 12px" variant="primary">
              {{
                $t("dataRecordModal.timeScoreTab.timePoinsSection.title")
              }}</b-badge
            >
            <div
              class="row"
              v-for="(timePoint,
              timePointIndex) in timeScoreDataObject.datapoints"
              :key="timePointIndex"
            >
              <div class="col-md-4">
                <label>
                  {{
                    $t("dataRecordModal.timeScoreTab.timePoinsSection.label")
                  }}
                  {{ timePointIndex + 1 }}
                </label>
              </div>
              <div class="col-md-2">
                <span v-if="timePoint.timescore === INVALID_SCORE">
                  {{ $t("dataRecordModal.general.notAvailable") }}
                </span>
                <span v-else>
                  {{
                    timePoint.timescore.toLocaleString("de-De", {
                      minimumFractionDigits: 3
                    })
                  }}
                </span>
              </div>
              <div v-if="timePoint.timescore !== INVALID_SCORE">
                <!-- Traffic Light -->
                <span>
                  <i
                    class="bx bxs-circle text-primary ml-2"
                    :class="trafficLightClassDecider(timePoint.timescore)"
                    style="font-size: 15px"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Time Score Median Chart -->
    <div class="row" v-if="timeScoreDataObject.timeScore !== INVALID_SCORE">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- Card Topic -->
            <b-badge class="mb-3" style="font-size: 12px" variant="primary">
              {{
                $t(
                  "dataRecordModal.timeScoreTab.timeScoreMedianChartSection.title"
                )
              }}</b-badge
            >
            <!-- Chart -->
            <div>
              <apexchart
                ref="medianComparisonChart"
                class="apex-charts mt-3 mb-3"
                dir="ltr"
                type="bar"
                height="300"
                :series="timeScoreComparisonChartData"
                :options="timeScoreComparisonChartOptions.chartOptions"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
