<script>
import QuotaService from "@/api/services/quota.service.js";
import WorksheetService from "@/api/services/worksheet.service.js";
import { EventBus } from "@/shared/util/event-bus.js";
import Multiselect from "vue-multiselect";
import xlsx from "xlsx";
import {
  quotaOptionObject,
  quotaSettingObject,
  quotaSettingsChartDataObject
} from "../../helper-objects";
import { QuotaSettingsChart } from "../../result-charts";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    datafileId: String,
    datafileTitle: String
  },
  components: {
    Multiselect
  },
  data() {
    return {
      /******** Data Variables *******/
      selectedColumn: null,
      datafileColumns: [],
      workingDatafileColumns: [],
      quotaSettings: [],
      quotaSettingsChartOptions: null,
      sampleQuotaSettings: [],

      /******** Operatiional Variables ******/
      isLoading: true,
      isValidQuotaSettings: true,
      cannotExportSettings: 0,
      settingsExportSuccess: 0,
      settingsUpdateSuccess: 0,
      settingsUpdateFailed: 0,
      validationFailed: 0,
      dismissSecs: 3,
      dismissCountDown: null
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  created() {
    EventBus.$on("showQuotaSettingsModal", async () => {
      this.isLoading = true;

      // Create Chart option for Quota Settings chart
      this.quotaSettingsChartOptions = new QuotaSettingsChart();

      //get headerRow to select column
      await this.getHeaderRow();

      // get Quota Settings
      await this.getQuotaSettings();
    });
  },
  mounted() {},
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    async getHeaderRow() {
      this.isQuotasLoading = true;
      try {
        const response = await WorksheetService.extractHeaderRow(
          this.datafileId
        );
        if (response) {
          this.datafileColumns = response;
          this.workingDatafileColumns = [...this.datafileColumns];
          this.isQuotasLoading = false;
        }
      } catch (error) {
        this.isQuotasLoading = false;
      }
    },
    async getQuotaSettings() {
      this.isLoading = true;
      // 1. Call Endpoint and Get Saved Quotat Settings
      let localQuotaSettings = [];
      try {
        const response = await QuotaService.getQuotaSettings(this.datafileId);

        if (response) {
          this.sampleQuotaSettings = response;
        }
      } catch (error) {
        // since we are deleting the object we have to handle it manualy
        this.sampleQuotaSettings = [];
      }

      // 2. Add Quota Setting to this.quotaSettings by chnaging format
      this.sampleQuotaSettings.forEach((setting) => {
        setting.isGetOptions = true;
        setting.isEditable = false;
        setting.settingsSaveCount = 1;
        setting.enableOptionCount = 0;
        setting.chartData = [
          {
            name: "Calculated",
            data: []
          }
        ];

        for (let i = 0; i < setting.quotaOptions.length; i++) {
          if (setting.quotaOptions[i].isEnabled) {
            setting.enableOptionCount += 1;
            let quotaChartData = new quotaSettingsChartDataObject();
            quotaChartData.x = setting.quotaOptions[i].name;
            quotaChartData.y = setting.quotaOptions[i].calculatedValue;
            quotaChartData.goals[0].value = Number(
              setting.quotaOptions[i].targetValue
            );
            setting.chartData[0].data.push(quotaChartData);
          }
        }

        // set chart height
        const baseHeight = 50;
        setting.chartHeight = 100 + baseHeight * setting.enableOptionCount;

        localQuotaSettings.push(setting);

        // remove select column from workingDatafileColumns
        const indexToRemove = this.workingDatafileColumns.indexOf(
          setting.originalColumnName
        );
        this.workingDatafileColumns.splice(indexToRemove, 1);
      });

      this.quotaSettings = [...localQuotaSettings];
      this.isLoading = false;
    },
    async getAnswerOptionsForSelectedHeader(datafileId, columnName) {
      try {
        const response = await WorksheetService.extractAnswerOptions(
          datafileId,
          columnName
        );

        return response;
      } catch (error) {
        console.log("getAnswerOptionsForSelectedHeader: ", error.message);
      }
      return [];
    },
    clickCloseButton() {
      EventBus.$emit("updateQuotaWidget");
      // EventBus.$emit("updateDataCleaningModal");
      this.$emit("close");
    },
    async saveQuotaSettings(quotaSettingsInput) {
      try {
        const response = await QuotaService.saveQuotaSettings(
          this.datafileId,
          quotaSettingsInput
        );
        this.settingsUpdateSuccess = this.dismissSecs;

        //4. Get Header Rows
        await this.getHeaderRow();

        // 5. Display saved settings in the charts
        await this.getQuotaSettings();

        return response;
      } catch (error) {
        console.log("saveQuotaSettings: ", error.message);
        this.isLoading = false;
        this.settingsUpdateSuccess = this.dismissSecs;
      }
    },
    async selectDatafileColumn(settingIndex) {
      this.quotaSettings[settingIndex].isGetOptions = false;
      // 1. Remove Selected Column from Headers
      // 2. Set value to renamedColumnName
      this.quotaSettings[settingIndex].renamedColumnName =
        this.quotaSettings[settingIndex].originalColumnName;

      // 3. Get all options for selected Column
      const returnOptions = await this.getAnswerOptionsForSelectedHeader(
        this.datafileId,
        this.quotaSettings[settingIndex].originalColumnName
      );

      // 4. push all options
      if (returnOptions.length > 0) {
        returnOptions.forEach((option) => {
          if (option) {
            let quotaSetting = new quotaOptionObject();
            quotaSetting.originalName = option;
            quotaSetting.name = option;
            this.quotaSettings[settingIndex].quotaOptions.push(quotaSetting);
          }
        });
      }
      this.quotaSettings[settingIndex].isGetOptions = true;

      // remove select column from workingDatafileColumns
      const indexToRemove = this.workingDatafileColumns.indexOf(
        this.quotaSettings[settingIndex].originalColumnName
      );
      this.workingDatafileColumns.splice(indexToRemove, 1);
    },
    addNewQuotaSetting() {
      const quotaSetting = new quotaSettingObject();
      this.quotaSettings.unshift(quotaSetting);
    },
    async clickSaveSettings(settingIndex) {
      this.isQuotasLoading = true;
      this.isValidQuotaSettings = true;
      this.settingsUpdateSuccess = 0;
      this.settingsUpdateFailed = 0;

      this.quotaSettings[settingIndex].quotaOptions.forEach((option) => {
        if (!option.originalName) this.isValidQuotaSettings = false;
      });

      if (this.isValidQuotaSettings) {
        // 1. Increase settingsSaveCount
        this.quotaSettings[settingIndex].settingsSaveCount += 1;

        //format quota settings to a matching format to send to the API
        const quotaSettingsInput = this.quotaSettings.map(
          ({ originalColumnName, renamedColumnName, quotaOptions }) => {
            return {
              originalColumnName,
              renamedColumnName,
              quotaOptions
            };
          }
        );

        // 2. Call end point to save settings
        await this.saveQuotaSettings(quotaSettingsInput);
      } else {
        this.validationFailed = this.dismissSecs;
        this.isValidQuotaSettings = false;
      }
    },
    async clickRemoveSettings(settingIndex) {
      this.isLoading = true;
      this.settingsUpdateSuccess = 0;
      this.settingsUpdateFailed = 0;

      // 1. Remove Setting from this.quotaSettings
      this.quotaSettings.splice(settingIndex, 1);

      // 2. Prepare quoat settings
      const quotaSettingsInput = this.quotaSettings.map(
        ({ originalColumnName, renamedColumnName, quotaOptions }) => {
          return {
            originalColumnName,
            renamedColumnName,
            quotaOptions
          };
        }
      );

      // 3. Save Quota Settings
      await this.saveQuotaSettings(quotaSettingsInput);
    },
    clickEditSettings(settingIndex) {
      let updatedArray = this.quotaSettings;
      updatedArray[settingIndex].isEditable = true;
      this.quotaSettings = [...updatedArray];
    },
    clickCancelSettings(settingIndex) {
      // Add back to workingDatafileColumns
      if (this.quotaSettings[settingIndex].originalColumnName) {
        const indexToAdd = this.datafileColumns.indexOf(
          this.quotaSettings[settingIndex].originalColumnName
        );
        this.workingDatafileColumns.splice(
          indexToAdd,
          0,
          this.quotaSettings[settingIndex].originalColumnName
        );
      }

      // remove from quotaSettings
      this.quotaSettings.splice(settingIndex, 1);
    },
    addAnotherOption(settingIndex) {
      const newOption = new quotaOptionObject();
      console.log("settingIndex: ", settingIndex);
      this.quotaSettings[settingIndex].quotaOptions.push(newOption);
    },
    clickExportQuotaSetting() {
      this.settingsExportSuccess = 0;
      this.cannotExportSettings = 0;

      if (this.quotaSettings.length > 0) {
        // 1. Create Array for Excel File
        let arrayOfArrays = new Array();

        // 2. Create Headers and push to arraysOfArray
        let headers = [
          this.$i18n.t(
            "datafileResultGeneral.quotaSettingsModal.exportTableHeaders.variable"
          ),
          this.$i18n.t(
            "datafileResultGeneral.quotaSettingsModal.exportTableHeaders.option"
          ),
          this.$i18n.t(
            "datafileResultGeneral.quotaSettingsModal.exportTableHeaders.calculatedValue"
          ),
          this.$i18n.t(
            "datafileResultGeneral.quotaSettingsModal.exportTableHeaders.targetValue"
          ),
          this.$i18n.t(
            "datafileResultGeneral.quotaSettingsModal.exportTableHeaders.additional"
          )
        ];
        arrayOfArrays.push(headers);

        // 3. Create Value Rows and push to arrayOfArray
        for (const quotaSetting of this.quotaSettings) {
          for (let i = 0; i < quotaSetting.quotaOptions.length; i++) {
            let row = new Array();
            // if this is firt option add column anme to array
            if (i === 0) {
              row.push(quotaSetting.renamedColumnName);
            } else {
              row.push(null);
            }

            row.push(
              quotaSetting.quotaOptions[i].name,
              quotaSetting.quotaOptions[i].calculatedValue,
              quotaSetting.quotaOptions[i].targetValue
            );

            // get Difference and if it more that 0 push to row
            const difference =
              quotaSetting.quotaOptions[i].targetValue -
              quotaSetting.quotaOptions[i].calculatedValue;
            if (difference > 0) {
              row.push(difference);
            } else row.push(0);

            arrayOfArrays.push(row);
          }
        }

        // 4. Create File
        var fileName = this.datafileTitle.concat("- Redem Quota Summary.xlsx");

        const XLSX = xlsx;
        var wb = XLSX.utils.book_new();
        wb.Props = {
          Title: this.datafileTitle.concat(" - Redem Quota Setting Summary"),
          CreatedDate: new Date()
        };
        wb.SheetNames.push("Redem Quota Setting Summary");

        var ws = XLSX.utils.aoa_to_sheet(arrayOfArrays);
        wb.Sheets["Redem Quota Setting Summary"] = ws;
        XLSX.writeFile(wb, fileName);

        this.settingsExportSuccess = this.dismissSecs;
      } else {
        this.cannotExportSettings = this.dismissSecs;
      }
    },
    changeOptionsOriginalName(settingIndex, optionIndex) {
      this.quotaSettings[settingIndex].quotaOptions[optionIndex].name =
        this.quotaSettings[settingIndex].quotaOptions[optionIndex].originalName;
    },
    changeEditStatus(settingIndex) {
      let updatedArray = this.quotaSettings;
      updatedArray[settingIndex].isEditable = false;
      this.quotaSettings = [...updatedArray];
    }
  }
};
</script>

<template>
  <!-- Quota Settings -->
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    no-close-on-backdrop
    size="xl"
    scrollable
    title="Scrollable Modal"
  >
    <!-- Modal Header  -->
    <template class="row" #modal-header>
      <div class="col-md-4">
        <h5>
          <i class="bx bxs-pie-chart-alt-2 mr-2"></i
          >{{ $t("datafileResultGeneral.quotaSettingsModal.title") }}
        </h5>
      </div>
      <div class="col-md-8 text-right">
        <b-button
          size="sm"
          variant="primary"
          class="mr-2"
          @click="clickExportQuotaSetting()"
        >
          <i class="bx bx-export mr-1"></i>
          {{
            $t(
              "datafileResultGeneral.quotaSettingsModal.button.exportQuotaSettings"
            )
          }}
        </b-button>
        <b-button
          size="sm"
          variant="success"
          class="mr-2"
          @click="addNewQuotaSetting()"
        >
          <i class="bx bx-plus mr-1"></i>
          {{
            $t(
              "datafileResultGeneral.quotaSettingsModal.button.addNewQuotaSettings"
            )
          }}
        </b-button>
        <b-button size="sm" variant="light" @click="clickCloseButton()">
          <i class="bx bx-x"></i>
        </b-button>
      </div>
    </template>

    <!-- Alerts -->
    <div class="row p-2">
      <div class="col-md-12">
        <!-- Export Success Alerts -->
        <b-alert
          :show="settingsExportSuccess"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
          class="col-md-12"
          variant="success"
          dismissible
          >{{
            $t("datafileResultGeneral.quotaSettingsModal.alert.exportSuccess")
          }}</b-alert
        >
        <!-- Cannot Export Alerts -->
        <b-alert
          :show="cannotExportSettings"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
          class="col-md-12"
          variant="warning"
          dismissible
          >{{
            $t("datafileResultGeneral.quotaSettingsModal.alert.cannotExport")
          }}</b-alert
        >
        <!-- Settings Update Success Alerts -->
        <b-alert
          :show="settingsUpdateSuccess"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
          class="col-md-12"
          variant="success"
          dismissible
          >{{
            $t(
              "datafileResultGeneral.quotaSettingsModal.alert.updateSettingsSuccess"
            )
          }}</b-alert
        >
        <!-- Settings Update Error Alerts -->
        <b-alert
          :show="settingsUpdateFailed"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
          class="col-md-12"
          variant="danger"
          dismissible
          >{{
            $t(
              "datafileResultGeneral.quotaSettingsModal.alert.updateSettingsFaild"
            )
          }}</b-alert
        >
      </div>
    </div>

    <!-- Display Quota Settings -->
    <div v-if="!this.isLoading && this.quotaSettings.length > 0" class="p-2">
      <div
        class="card"
        v-for="(setting, settingIndex) in this.quotaSettings"
        :key="settingIndex"
      >
        <!-- Enable Edit -->
        <div class="card-body" v-if="setting.isEditable">
          <!-- Validation Error-->
          <div class="row">
            <div class="col-md-12">
              <b-alert
                :show="validationFailed"
                @dismissed="dismissCountDown = 0"
                @dismiss-count-down="countDownChanged"
                class="col-md-12"
                variant="danger"
                dismissible
                >{{
                  $t(
                    "datafileResultGeneral.quotaSettingsModal.alert.validationError"
                  )
                }}</b-alert
              >
            </div>
          </div>

          <!-- Select Column -->
          <div class="row align-items-center">
            <div class="col-md-6">
              <label>
                {{
                  $t(
                    "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.selectColumnLabel"
                  )
                }}:
              </label>
              <select
                v-if="setting.originalColumnName"
                class="form-control"
                :disabled="setting.originalColumnName"
                style="
                  background: #f5f5f5;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                "
              >
                <option>{{ setting.originalColumnName }}</option>
              </select>
              <multiselect
                v-else
                v-model="setting.originalColumnName"
                :options="workingDatafileColumns"
                @input="selectDatafileColumn(settingIndex)"
                :placeholder="
                  $t('uploadProcessStep2.form.general.selectColumn.placeholder')
                "
              ></multiselect>
            </div>
            <div class="col-md-6" v-if="setting.originalColumnName">
              <label>
                {{
                  $t(
                    "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.renameColumnLabel"
                  )
                }}:
              </label>
              <b-form-input v-model="setting.renamedColumnName"></b-form-input>
            </div>
          </div>

          <!-- Loader only for Get Answers -->
          <div
            class="row justify-content-center mt-5"
            v-if="!setting.isGetOptions"
          >
            <div class="col-6 text-center pt-4 pb-4">
              <i class="bx bx-loader-circle bx-spin mr-2"></i>
              {{
                $t(
                  "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.fetchingOptions"
                )
              }}
            </div>
          </div>

          <!-- Options -->
          <div
            class="mt-5"
            v-if="setting.isGetOptions && setting.quotaOptions.length > 0"
          >
            <!-- Column Header -->
            <div class="row">
              <div class="col-md-1">
                <label>
                  {{
                    $t(
                      "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.showLabel"
                    )
                  }}
                </label>
              </div>
              <div class="col-md-4">
                <label>
                  {{
                    $t(
                      "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.originalOptionLabel"
                    )
                  }}</label
                >
              </div>
              <div class="col-md-5">
                <label>
                  {{
                    $t(
                      "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.renameOptionLabel"
                    )
                  }}
                </label>
              </div>
              <div class="col-md-2">
                <label>
                  {{
                    $t(
                      "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.tragetLabel"
                    )
                  }}</label
                >
              </div>
            </div>
            <!-- Option Array -->
            <div
              class="row mb-2 align-items-center"
              v-for="(option, optionIndex) in setting.quotaOptions"
              :key="optionIndex"
            >
              <div class="col-md-1">
                <input
                  variant="primary"
                  type="checkbox"
                  v-model="option.isEnabled"
                />
              </div>
              <div class="col-md-4">
                <b-form-input
                  v-if="option.originalName"
                  v-model="option.originalName"
                  disabled
                  style="
                    background: #f5f5f5;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                  "
                ></b-form-input>
                <b-form-input
                  v-else
                  v-model="option.originalName"
                  @input="changeOptionsOriginalName(settingIndex, optionIndex)"
                ></b-form-input>
              </div>
              <div class="col-md-5">
                <b-form-input type="text" v-model="option.name"></b-form-input>
              </div>
              <div class="col-md-2 text-right">
                <b-form-input
                  type="number"
                  v-model.number="option.targetValue"
                  :placeholder="
                    $t(
                      'datafileResultGeneral.quotaSettingsModal.quotaSettingForm.tragetPlaceholder'
                    )
                  "
                ></b-form-input>
              </div>
            </div>
            <!-- Additional Button-->
            <div class="row mt-4">
              <div class="col-md-12">
                <b-button
                  variant="light"
                  class="mr-2"
                  @click="addAnotherOption(settingIndex)"
                >
                  <i class="bx bx-plus mr-1"></i>
                  {{
                    $t(
                      "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.button.addAnotherOption"
                    )
                  }}
                </b-button>
              </div>
            </div>
          </div>

          <!-- Action Button -->
          <div class="row mt-5">
            <div class="col-md-12">
              <!-- Save Button -->
              <b-button
                v-if="setting.isGetOptions && setting.quotaOptions.length > 0"
                variant="success"
                class="mr-2"
                size="sm"
                @click="clickSaveSettings(settingIndex)"
              >
                <i class="bx bx-save mr-1"></i>
                {{
                  $t(
                    "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.button.save"
                  )
                }}
              </b-button>
              <!-- Remove Button -->
              <b-button
                v-if="
                  setting.quotaOptions.length > 0 &&
                  setting.settingsSaveCount > 0
                "
                variant="danger"
                class="mr-2"
                size="sm"
                @click="clickRemoveSettings(settingIndex)"
              >
                <i class="bx bx-trash mr-1"></i>
                {{
                  $t(
                    "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.button.remove"
                  )
                }}
              </b-button>
              <!-- Cancel Button -->
              <b-button
                variant="light"
                class="mr-2"
                size="sm"
                @click="
                  setting.settingsSaveCount === 0
                    ? clickCancelSettings(settingIndex)
                    : changeEditStatus(settingIndex)
                "
              >
                <i class="bx bx-x mr-1"></i>
                {{
                  $t(
                    "datafileResultGeneral.quotaSettingsModal.quotaSettingForm.button.cancel"
                  )
                }}
              </b-button>
            </div>
          </div>
        </div>

        <!-- Disble Edit -->
        <div class="card-body" v-else>
          <div class="row">
            <div class="col-md-6">
              <h6>{{ setting.renamedColumnName }}</h6>
            </div>
            <div class="col-md-6 text-right">
              <b-button
                variant="warning"
                class="mr-2"
                size="sm"
                @click="clickEditSettings(settingIndex)"
              >
                <i class="bx bxs-pencil mr-1"></i>
                {{
                  $t(
                    "datafileResultGeneral.quotaSettingsModal.button.editSetting"
                  )
                }}
              </b-button>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <apexchart
                lazy
                type="bar"
                :height="setting.chartHeight"
                :options="quotaSettingsChartOptions.chartOptions"
                :series="[...setting.chartData]"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- No Quota Settings -->
    <div
      class="p-2 row justify-content-center"
      v-if="!this.isLoading && this.quotaSettings.length === 0"
    >
      <div class="col-6 text-center pt-3 pb-5">
        {{ $t("datafileResultGeneral.quotaSettingsModal.noSettings") }}
      </div>
    </div>

    <!-- Loader -->
    <div class="p-2 row justify-content-center" v-if="this.isLoading">
      <div class="col-6 text-center pt-3 pb-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </b-modal>
</template>
